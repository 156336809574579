@font-face {
  font-family: 'din-condensed-bold';
    src: url('./fonts/d-dincondensed-bold-webfont.woff2') format('woff2'),
         url('./fonts/d-dincondensed-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'din-condensed';
    src: url('./fonts/d-dincondensed-webfont.woff2') format('woff2'),
         url('./fonts/d-dincondensed-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.dinBold {
  text-align: center;
  font-family: din-condensed, sans-serif;
  font-weight: 400;
  font-style: normal;
}
.dinlight {
  font-family: din-condensed, sans-serif;
  font-weight: 300;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
}

body {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,064e68+100 */
  background: #000000; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #000000 0%,
    #064e68 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #000000 0%,
    #064e68 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #000000 0%,
    #064e68 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#064e68',GradientType=0 ); /* IE6-9 */

  margin: 0;
  font-family: din-condensed, sans-serif;
  font-weight: 300;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body:not(.fullscreen) #content-wrapper {
  overflow-x: hidden;
}

/*
  hack to get the reorder, item select, show text change to work
*/
body > li.inActiveDrag .overlay-text {
  opacity: 1;
}

/*
Locale styling 
*/

html[lang=fr] .foreground,
html[lang=es] .foreground {
  font-size: 30px;
}

html[lang=de] #slider-question .answer__title,
html[lang=it] #slider-question .answer__title,
html[lang=nl] #slider-question .answer__title {
  font-size: 17px;
}