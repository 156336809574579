.jigsaw {
  text-align: center;
  position: relative;
  margin: -40px auto;
  width: 650px;
  height: 320px;
  
  left: 50%;
  transform: translateX(-50%) scale(0.5);
}

@media only screen and (min-width: 768px) {
  .jigsaw {
    left: auto;
    transform: scale(1);
    margin: 20px auto;
  }
}

.jigsaw__shuffled-board {
  padding: 0;
  overflow: hidden;
  /* background-color: red; */
}

.jigsaw__solved-board {
  margin: 10px auto;
  border: solid 1px 0ff;
  box-shadow: 0 0 10px 5px #0ff, 0 0 5px 2px #0ff;
  background-color: rgba(0, 0, 0, 0.6);
  width: 296px;
  font-size: 0;
  padding: 0;
}

.jigsaw span {
  position: relative;
  text-align: left;
  display: inline-block;
  width: 148px;
  height: 148px;
}

.jigsaw__shuffled-board > img {
  box-shadow: 2px 2px 20px 2px rgba(0, 0, 0, 0.8);
}

.jigsaw__shuffled-board > span:nth-child(2n-1) {
  position: absolute;
  left: 0;
  top: 0;
}
.jigsaw__shuffled-board > span:nth-child(2n) {
  position: absolute;
  right: 0;
  top: 0;
}

.jigsaw__shuffled-board > span:nth-child(3) {
  top: 160px;
}
.jigsaw__shuffled-board > span:nth-child(4) {
  top: 160px;
}

.jigsaw li:empty:hover:before {
  opacity: 1;
}

.jigsaw .piece {
  width: 100%;
  height: 100%;
}

.jigsaw span div {
  width: 100px;
  height: 100px;
  line-height: 100px;
  position: absolute;
  cursor: grab;
  font-size: 20px;
  text-align: center;
  /* background-color: blue; */
  transition: transform 200ms ease, box-shadow 200ms ease;
}

.jigsaw span img:hover {
  z-index: 2;
  transform: scale(1.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}
