/* *** General fullscreen functionality styles **** */
body.fullscreen header,
body.fullscreen footer,
body.fullscreen .titleArea,
body.fullscreen #next-button,
body.fullscreen #progress-bar,
body.fullscreen #debugger {
  display: none;
}
body.fullscreen,
body.fullscreen #root,
body.fullscreen .interactionArea {
  height: 100%;
}
body.fullscreen #iframe-area {
  width: 100%; 
  margin: 0; 
  height: 100%;
}
body.fullscreen #content-wrapper,
body.fullscreen .iframeWrap {
  padding: 0;
}
body.fullscreen .iframe {
  height: 0px; 
  width: 0px; 
  max-height: 100%; 
  max-width: 100%; 
  min-height: 100%; 
  min-width: 100%;
}

/* *** If css is needed whilst in fullscreen mode via Fullscreen API *** */
:-webkit-full-screen {
  /* properties */
}

:-moz-full-screen {
  /* properties */
}

:-ms-fullscreen {
  /* properties */
}

:full-screen { /*pre-spec */
  /* properties */
}

:fullscreen { /* spec */
  /* properties */
}

/* deeper elements */
:-webkit-full-screen video {
  width: 100%;
  height: 100%;
}

/* styling the backdrop*/
::backdrop {
  /* properties */
}
::-ms-backdrop {
  /* properties */
}